// i18next-extract-mark-ns-start capabilities-electrification

import { LINKS, ELECTRIFICATION_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import ResourceListing from '@components/views/ResourceListing';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilityElectrificationPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Simulating electric vehicle propulsion technology </h2>

						<p>
							Reducing emissions and the carbon footprint of our society have become imperatives,
							requiring the mobility industry to adapt and develop innovative technologies to strive for a cleaner sustainable transport system.
							With stricter global emissions restrictions and target deadlines looming, manufacturers are facing ever increasing challenges to
							create vehicles that meet the new environmental and legal requirements.
						</p>
						<p>
							Electric vehicles (EV) provide low to zero emission powertrains across several technology options including battery electric vehicle (BEV),
							plug in hybrid electric vehicle (PHEV), self-charging hybrid electric vehicle (HEV) and fuel cell hydrogen electric (FCEV).
						</p>
						<p>
							Battery electric vehicles are perfect for urban applications offering a balance between charging time, range and performance.
						</p>
						<p>
							Hybrids which include a combination of electric and internal combustion (using either traditional or e-fuels) offer
							increased flexibility by extending the range whilst reducing overall emissions compared to traditional engines.
						</p>
						<p>
							Fuel cell technology is moving at tremendous pace with governments around the world offering funding for many hydrogen economy related activities.
							For industries including heavy duty, marine and aerospace, fuel cell technology is showing promise as an alternative to plug in electrification
							where range or remote usage is a major factor.
						</p>
						<p>
							With so many options in play, this leaves Original Equipment Manufacturers (OEMs) and manufacturers with the challenge of
							simultaneously developing smaller Internal Combustion (IC) engines for hybrid systems, designing battery and motor units and
							investing in fuel cell research - all with less resources and tighter budgets.
						</p>
						<p>
							In the initial stages of development, physical prototype modelling is expensive, requiring virtual simulation tools to answer many of the 'What ifs'
							before committing to a chosen design path. Complete vehicle system models are vital in the selection of the correct balance of
							components to meet performance, emissions, cost and drivability requirements for all types of electric vehicles under different duty cycles
							allowing the right technology to be chosen for the right application.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_TechnologyElectrification")}
						className="widget__sibling__pages">
						<AssetList>
							{ELECTRIFICATION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default CapabilityElectrificationPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-electrification", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/electrification/Realis-Simulation_1920x1080_FuelCell1_largerframe.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { capabilities: { in: "Electrification" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
